import {customElement, inject, bindable} from "aurelia-framework";
import * as _ from "lodash";
import {Client} from "../api/client";

@customElement("crm-qa-survey-evaluation")
@inject(Client)
export class Evaluation {

    client;

    @bindable from;
    @bindable to;
    @bindable continent;
    @bindable country;
    @bindable journey;

    participants;
    ratings = [];

    constructor(client) {
        this.client = client;
    }

    update() {
        let params = [];
        let query = '';

        if (this.continent) {
            params.push('continent=' + this.continent.id);
        }
        if (this.country) {
            params.push('country=' + this.country.id);
        }
        if (this.journey) {
            params.push('journey=' + this.journey.id);
        }

        if (params.length) {
            query = '?' + params.join('&');
        }

        this.participants = null;
        this.ratings = {};

        this.client.get('crm-qa-survey/evaluation' + query)
            .then(data => {
                this.participants = data.participants;
                this.ratings = data.ratings;

                _.forEach(data.ratings, rating => {
                    rating.group = this.client.get('crm-qa-survey/question_group/' + rating.group)
                        .then(group => {
                            rating.group = group;
                        });
                });
            });
    }

    //noinspection JSUnusedGlobalSymbols
    fromChanged() {
        this.update();
    }

    //noinspection JSUnusedGlobalSymbols
    toChanged() {
        this.update();
    }

    //noinspection JSUnusedGlobalSymbols
    continentChanged() {
        this.update();
    }

    //noinspection JSUnusedGlobalSymbols
    countryChanged() {
        this.update();
    }

    //noinspection JSUnusedGlobalSymbols
    journeyChanged() {
        this.update();
    }

    attached() {
        this.update();
    }
}
