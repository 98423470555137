import React from "react"
import {useSelector} from "react-redux"
import {Icon, IconButton, Loader} from "rsuite"
import {Container} from "aurelia-framework"
import {UserClient} from "../../api/user-client"
import {useCostsRealCostsOriginAddAssignmentsMutation, useInterfaceImplementationsQuery} from "../../store/api"
import {selectEntities} from "../../dialog/universal-entity-select"
import {selectAssignmentLoading} from "../store/assignment-slice"

const userClient = Container.instance.get(UserClient)

export default function LinkButtons({i18n, origin, creditor}) {
    const {isLoading, data: models} = useInterfaceImplementationsQuery("costs/real-costs-destination")
    const loading = useSelector(selectAssignmentLoading)
    const [doLink, {isFetching}] = useCostsRealCostsOriginAddAssignmentsMutation()

    async function link(combinedId) {
        let field = "creditor"
        let conditions = undefined

        switch (combinedId) {
            case "order/order-item":
                field = "serviceProviders"
                conditions = !creditor?.id ? undefined : {
                    $or: [
                        {[field]: {$exists: false}},
                        {[field]: {$eq: creditor}},
                    ]
                }
                break

            case "tourism/journey":
                field = "serviceProvider"
                break
        }

        if ("order/order-item" === combinedId) {
            // @todo setting
            if ('605c7575ca2ceb2a3e1a88b2' === userClient.getUser()?.organization?.id) {
                delete conditions.$or
            }

            const {wasCancelled, output} = await selectEntities("order/order", conditions)

            if (wasCancelled || !output.length) {
                return
            }

            const {id, modelId} = output[0]
            combinedId = "order/order-item"
            conditions = !creditor?.id ? undefined : {
                order: {$eq: {id, modelId}},
                $or: [
                    {serviceProvider: {$exists: false}},
                    {serviceProvider: {$eq: creditor}},
                ]
            }
        }

        // @todo setting
        if ('605c7575ca2ceb2a3e1a88b2' === userClient.getUser()?.organization?.id) {
            delete conditions.$or
        }

        const {wasCancelled, output} = await selectEntities(combinedId, conditions)

        if (!wasCancelled && output.length) {
            doLink({origin, destinations: output.map(({id, modelId}) => ({id, modelId}))})
        }
    }

    return isLoading ? (
        <Loader/>
    ) : models.map(({combinedId, label}) => (
        <IconButton key={combinedId} loading={isFetching} disabled={loading}
                    icon={<Icon icon="plus"/>} onClick={() => link(combinedId)}>
            {i18n.tr(label)}
        </IconButton>
    ))
}
