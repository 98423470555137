import {customElement} from "aurelia-framework";

@customElement('sio-crm-qa-survey-overview')
export class CrmQaSurveyOverview {
    config = [
        {
            heading: 'crm-qa-survey.survey.menu',
            content: '<sio-table config="crm-qa-survey/survey"></sio-table>',
            count: '{modelId: "crm-qa-survey/survey"}'
        },
        {
            heading: 'Berichte',
            content: '<sio-table config="crm-qa-survey/report"></sio-table>',
            count: '{modelId: "crm-qa-survey/report"}'
        }
    ];
}
