import React from "react"
import {useSelector} from "react-redux"
import {Button} from "rsuite"
import {useCostsRealCostsOriginSaveMutation} from "../../store/api"
import {selectAssignmentLoading, selectRealCostOriginSaveParams} from "../store/assignment-slice"
import {useStyles} from "./constants"

export default function SaveButton({origin, i18n}) {
    const params = useSelector(selectRealCostOriginSaveParams)
    const loading = useSelector(selectAssignmentLoading)
    const [doSave, {isFetching}] = useCostsRealCostsOriginSaveMutation()
    const {danger} = useStyles()

    return (
        <Button loading={isFetching} disabled={loading} className={danger} onClick={() => doSave({origin, ...params})}>
            {i18n.tr("sio.save")}
        </Button>
    )
}
