import {useSelector} from "react-redux"
import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit"
import {
    costsRealCostsOriginAssignmentDeleted,
    costsRealCostsOriginLoaded,
    costsRealCostsOriginLoading
} from "../../store/api"

/**
 * @typedef {{ amount: int, destination: ObjectRef, percentage: Number, calculatedSum: Money }} Assignment
 */

const assignmentAdapter = createEntityAdapter()
const assignmentSlice = createSlice({
    name: "costs/assignment",
    initialState: assignmentAdapter.getInitialState({
        loading: true
    }),
    reducers: {
        setAssignmentPrice: {
            prepare: (id, amount) => ({payload: {id, amount}}),
            reducer(state, {payload: {id, amount}}) {
                assignmentAdapter.updateOne(state, {id, changes: {amount}})
            }
        },
        selectCurrency: {
            reducer(state, {payload}) {
                state.currency = payload
            }
        },
        clear: {
            reducer(state) {
                state.currencies = undefined
                state.currency = undefined
                assignmentAdapter.removeAll(state)
            }
        }
    },
    extraReducers: builder => builder
        .addMatcher(costsRealCostsOriginLoading, state => {
            state.loading = true
        })
        .addMatcher(costsRealCostsOriginLoaded, (state, {payload: {assignments, currencies}}) => {
            state.currencies = currencies ?? []
            state.loading = false

            if (!state.currency) {
                if (assignments[0]?.price?.currency) {
                    state.currency = assignments[0].price.currency
                } else if (currencies.length) {
                    state.currency = currencies[0]
                }
            }

            if (assignments?.length) {
                assignmentAdapter.upsertMany(
                    state,
                    assignments.map(({price, ...rest}) => ({amount: price?.amount ?? 0, ...rest}))
                )
            }
        })
        .addMatcher(costsRealCostsOriginAssignmentDeleted, (state, {meta: {arg: {originalArgs: {id}}}}) => {
            assignmentAdapter.removeOne(state, id)
        })
})

const assignment = assignmentSlice.reducer
export default assignment

export const {
    /** @type {(id: string, price: Money) => any} */
    setAssignmentPrice,
    /** @type {(currency: string) => any} */
    selectCurrency,
    /** @type {() => any} */
    clear
} = assignmentSlice.actions

const {
    /** @type {(state: any) => Assignment[]} */
    selectAll: selectAllAssignments,
    /** @type {(state: any, id: string) => Assignment} */
    selectById: selectAssignmentById,
    selectIds: selectAssignmentIds,
    selectTotal: selectAssignmentsTotal
} = assignmentAdapter.getSelectors(state => state.costs.assignment)

const selectAssignment = createSelector(state => state.costs, ({assignment}) => assignment)
const selectAssignmentAmounts = createSelector(
    selectAllAssignments,
    assignments => assignments.map(({id, amount}) => ({id, amount}))
)

export const selectAssignmentCurrencies = createSelector(selectAssignment, ({currencies}) => currencies)
export const selectAssignmentCurrency = createSelector(selectAssignment, ({currency}) => currency)
/** @type {(state: any) => boolean} */
export const selectAssignmentLoading = createSelector(selectAssignment, ({loading}) => loading)
export const selectRealCostOriginQueryParams = createSelector(
    [selectAssignmentAmounts, selectAssignmentCurrency],
    (amounts, currency) => ({
        currency,
        prices: amounts.map(({id, amount}) => ({id, price: {amount, currency}}))
    })
)
export const selectRealCostOriginSaveParams = createSelector(
    [selectAssignmentAmounts, selectAssignmentCurrency],
    (amounts, currency) => ({
        prices: amounts.map(({id, amount}) => ({id, price: {amount, currency}}))
    })
)

/** @type {(id: string) => Assignment} */
export const useAssignment = id => useSelector(state => selectAssignmentById(state, id))
/** @type {() => string[]} */
export const useAssignmentIds = () => useSelector(state => selectAssignmentIds(state))

/** @type {(id: string) => int} */
export const useAssignmentAmount = id => useSelector(state => selectAssignment(state).entities[id]?.amount ?? 0)
