import moment from "moment-timezone";
import {LogManager} from "aurelia-framework";

const logger = LogManager.getLogger('DateInput');

export const transformComponentDateToModelDate = (date, time, format, {convertToUtc, timezone}) => {
    logger.debug('transformComponentDateToModelDate', date, time, format, {convertToUtc, timezone});

    if (!date) {
        return null;
    }

    const timeString = time ? ('T' + time + ':00') : null;

    if (convertToUtc) {
        return new Date(date + (timeString || "T00:00:00")).toISOString().substr(0, 19);
    }

    return (timezone ? moment.tz(date + timeString, timezone) : moment(date + timeString))?.format(format);
}

const modelDateFromStringToMoment = (date, format, convertToUtc, timezone) => {
    if (!date || '' === date) {
        return null;
    }

    date = convertToUtc ? moment.utc(date).local() : moment(date, format);

    if (!date.isValid()) {
        return null;
    }

    if (timezone) {
        return date.tz(timezone);
    }

    return date;
};

export const transformModelDateToComponentDate = (modelDate, format, {convertToUtc, timezone}) => {
    logger.debug('transformModelDateToComponentDate', modelDate, format, {convertToUtc, timezone});

    modelDate = modelDateFromStringToMoment(modelDate, format, convertToUtc, timezone);

    if (null === modelDate) {
        return {
            date: null,
            time: '00:00'
        };
    }

    if (timezone) {
        modelDate.tz(timezone);
    }

    return {
        date: modelDate.format('YYYY-MM-DD'),
        time: modelDate.format('HH:mm')
    };
}
