import {DialogController} from 'aurelia-dialog';
import {inject} from 'aurelia-framework';
import {Client} from "../api/client";

@inject(DialogController, Client)
export class CostsDialog {

    dialogController;
    loading;
    items = [];

    constructor(dialogController, client) {
        this.dialogController = dialogController;
        this.client = client;
    }

    async activate(context) {
        this.loading = true
        const {destination, calculatedCostsEntries} = context

        if (destination) {
            const {items, sum} = (await this.client.post("costs/query/real-costs/destination", {destination})).data;

            this.items = items
            this.sum = sum
            this.singleOrigin = false

        } else if (calculatedCostsEntries?.length) {
            const origins = calculatedCostsEntries.map(({origin}) => origin)

            this.singleOrigin = 1 === new Set(origins.map(({id}) => id)).size
            this.reference = -1 !== calculatedCostsEntries.findIndex(({reference}) => !!reference)
            this.items = context.calculatedCostsEntries

            if (this.singleOrigin) {
                this.origin = origins[0]
            }
        }

        this.loading = false
    }
}
