import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Col, Divider, Form, Grid, Icon, Loader, Row} from "rsuite"
import {useCostsRealCostsOriginQuery} from "../../store/api"
import StatsLabel from "../../statistics/time-aggregation/stats-label"
import {clear, selectRealCostOriginQueryParams, useAssignmentIds} from "../store/assignment-slice"
import {labelCol, percentageCol, priceCol, useStyles} from "./constants"
import CurrencySelect from "./currency-select"
import SaveButton from "./save-button"
import Assignment from "./assignment"
import LinkButtons from "./link-buttons"

export default function OriginForm({currencyValueConverter, origin, creditor, i18n}) {
    const dispatch = useDispatch()
    const params = useSelector(selectRealCostOriginQueryParams)
    const {data = {}, isLoading, isFetching} = useCostsRealCostsOriginQuery({origin, ...params})
    const ids = useAssignmentIds()
    const {panel, check} = useStyles()

    useEffect(
        () => () => {
            dispatch(clear())
        },
        []
    )

    return isLoading ? (
        <Loader/>
    ) : (
        <div className={"panel panel-default " + panel}>
            <Form layout="horizontal" fluid>
                <Grid fluid>
                    <Row>
                        <Col xs={18}>
                            <CurrencySelect/>
                        </Col>
                        {data.assignments.length && (
                            <Col xs={6} className="text-right">
                                <SaveButton origin={origin} i18n={i18n}/>
                            </Col>
                        )}
                    </Row>

                    <Divider/>

                    <Row>
                        <Col {...labelCol}>
                            <strong><StatsLabel label={data.origin}/></strong>
                        </Col>
                        <Col {...priceCol}>
                            <strong>{currencyValueConverter.toView(data.costs)}</strong>
                        </Col>
                        <Col {...percentageCol}>
                        </Col>
                        <Col {...percentageCol}>
                            kalkuliert
                        </Col>
                    </Row>

                    <Divider/>

                    {ids.map(id => (
                        <Assignment key={id} id={id} origin={origin} currencyValueConverter={currencyValueConverter}/>
                    ))}

                    <Row>
                        <Col {...labelCol}>
                            <LinkButtons i18n={i18n} origin={origin} creditor={creditor}/>
                        </Col>
                    </Row>

                    <Divider/>

                    <Row>
                        {(!data.difference || 0 === data.difference.amount) ? (
                            <>
                                <Col {...labelCol}></Col>
                                <Col {...priceCol}>
                                    <Icon icon="check" className={check}/>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col {...labelCol}>
                                    {isFetching ? (
                                        <Loader/>
                                    ) : (
                                        <strong>{data.difference.amount > 0 ? "noch zuzuweisen" : "zu viel zugewiesen"}</strong>
                                    )}
                                </Col>
                                <Col {...priceCol}>
                                    {isFetching ? (
                                        <Loader/>
                                    ) : (
                                        <strong>{currencyValueConverter.toView(data.difference)}</strong>
                                    )}
                                </Col>
                                <Col {...percentageCol}>
                                    {isFetching ? (
                                        <Loader/>
                                    ) : (
                                        <strong>{data.differencePercentage + "%"}</strong>
                                    )}
                                </Col>
                            </>
                        )}
                    </Row>
                </Grid>
            </Form>
        </div>
    )
}
