import React from "react";
import {render, unmountComponentAtNode} from "react-dom";
import {bindable, bindingMode, customElement, inject, noView} from "aurelia-framework";
import {CurrencyValueConverter} from "../currency/currency-value-converter";
import {I18N} from "aurelia-i18n";
import {Provider} from "react-redux";
import store from "../store/store";
import OriginForm from "./origin/form";

@noView
@inject(Element, CurrencyValueConverter, I18N)
@customElement("cost-assignment")
export class CostAssignment {

    @bindable({defaultBindingMode: bindingMode.oneTime}) realCostsOrigin;

    constructor(container, cvc, i18n) {
        this.container = container;
        this.cvc = cvc;
        this.i18n = i18n;
    }

    attached() {
        const origin = this.realCostsOrigin?.id ? {
            id: this.realCostsOrigin.id,
            modelId: this.realCostsOrigin.modelId
        } : undefined

        const creditor = this.realCostsOrigin?.creditor?.id ? {
            id: this.realCostsOrigin.creditor.id,
            modelId: this.realCostsOrigin.creditor.modelId,
        } : undefined

        render(
            <Provider store={store}>
                <OriginForm currencyValueConverter={this.cvc} i18n={this.i18n} origin={origin} creditor={creditor}/>
            </Provider>,
            this.container
        );
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
