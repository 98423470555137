import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Col, FormControl} from "rsuite"
import {useDebouncedChange} from "../../utilities/debounce"
import {CurrencyInput} from "../../form/rsuite-form"
import {selectAssignmentCurrency, setAssignmentPrice, useAssignmentAmount} from "../store/assignment-slice"
import {priceCol} from "./constants"

export default function AssignmentPrice({id}) {
    const dispatch = useDispatch()
    const amount = useAssignmentAmount(id)
    const currency = useSelector(selectAssignmentCurrency)
    const [value, onChange] = useDebouncedChange(amount, 250, amount => {
        dispatch(setAssignmentPrice(id, amount))
    })

    return (
        <Col {...priceCol}>
            <FormControl
                name={id}
                accepter={CurrencyInput}
                value={{amount: value, currency}}
                onChange={({amount}) => onChange(amount)}
            />
        </Col>
    )
}
