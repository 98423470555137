import React from "react"
import {useSelector} from "react-redux"
import {Icon, IconButton} from "rsuite"
import {useCostsRealCostsOriginDeleteAssignmentMutation} from "../../store/api"
import {confirm} from "../../dialog/confirm"
import {selectAssignmentLoading} from "../store/assignment-slice"
import {useStyles} from "./constants"

export default function DeleteButton({origin, id}) {
    const loading = useSelector(selectAssignmentLoading)
    const [doDelete, {isFetching}] = useCostsRealCostsOriginDeleteAssignmentMutation()
    const {danger} = useStyles()

    function onClick() {
        confirm("Eintrag löschen", "Sind Sie sicher?")
            .then(() => doDelete({origin, id}))
    }

    return (
        <IconButton
            loading={isFetching}
            disabled={loading}
            className={danger}
            size="xs"
            icon={<Icon icon="trash2"/>}
            onClick={() => onClick()}
        />
    )
}
