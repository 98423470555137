import React from "react"
import {useSelector} from "react-redux"
import {Col, Icon, IconButton, Loader, Row} from "rsuite"
import StatsLabel from "../../statistics/time-aggregation/stats-label"
import {selectAssignmentLoading, useAssignment} from "../store/assignment-slice"
import {actionCol, labelCol, percentageCol, useStyles} from "./constants"
import AssignmentPrice from "./assignment-price"
import DeleteButton from "./delete-button";

export default function Assignment({id, origin, currencyValueConverter}) {
    const loading = useSelector(selectAssignmentLoading)
    const {destination, percentage, calculatedSum, additionalInfo = []} = useAssignment(id)
    const {assignment} = useStyles()

    return (
        <Row className={assignment}>
            <Col {...labelCol}>
                <StatsLabel label={destination}/>

                {additionalInfo.length && (
                    <div><small>{additionalInfo.join("; ")}</small></div>
                )}
            </Col>

            <AssignmentPrice id={id}/>

            <Col {...percentageCol}>
                {loading ? <Loader/> : percentage ? percentage + "%" : null}
            </Col>

            <Col {...percentageCol}>
                {loading ? <Loader/> : calculatedSum && currencyValueConverter.toView(calculatedSum)}
            </Col>

            <Col {...actionCol}>
                <DeleteButton origin={origin} id={id}/>
            </Col>
        </Row>
    )
}
