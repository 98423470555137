import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {ControlLabel, FormControl, FormGroup, Radio, RadioGroup} from "rsuite"
import {selectAssignmentCurrencies, selectAssignmentCurrency, selectCurrency} from "../store/assignment-slice"

export default function CurrencySelect() {
    const dispatch = useDispatch()
    const currencies = useSelector(selectAssignmentCurrencies)
    const currency = useSelector(selectAssignmentCurrency)

    if (typeof currencies === 'undefined'){
        return null
    }

    return 2 > currencies.length ? null : (
        <FormGroup>
            <ControlLabel>Währung:</ControlLabel>
            <FormControl accepter={RadioGroup} inline value={currency} onChange={c => dispatch(selectCurrency(c))}>
                {currencies.map(currency => (
                    <Radio key={currency} value={currency}>{currency}</Radio>
                ))}
            </FormControl>
        </FormGroup>
    )
}
