import React from "react";
import {render, unmountComponentAtNode} from "react-dom";
import {bindable, customElement, inject, LogManager, noView} from 'aurelia-framework';
import {DateRangePicker} from "rsuite";
import {transformModelDateToComponentDate} from "./transformer";

import 'rsuite/dist/styles/rsuite-default.css';
import './date-range-input.less';


const locale = {
    monday: 'Mo',
    tuesday: 'Di',
    wednesday: 'Mi',
    thursday: 'Do',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'So',
    ok: 'OK',
    today: 'heute',
    yesterday: 'gestern',
    last7Days: 'Letzte Woche',
    formattedMonthPattern: "MMM YYYY",
    formattedDayPattern: "d.M.YYYY",
};

Date.prototype.toPlainISO = function () {
    return this.toISOString().substr(0, 19);
};

@noView()
@customElement('sio-date-range-input')
@inject(Element)
export class DateRangeInput {

    @bindable({defaultBindingMode: 2}) modelDate;
    @bindable options = {};

    constructor(element) {
        this.element = element;
        this.onChange = value => this.modelDate = value.map(v => !v ? undefined : moment(v).local().format("YYYY-MM-DD"));
        this.renderValue = value => !value ? null : value.map(v => moment(v).local().format("D.M.YY")).join(' – ');
    }

    detached() {
        unmountComponentAtNode(this.element);
    }

    modelDateChanged() {
        this.render();
    }

    optionsChanged() {
        this.render();
    }

    render() {

        const value = (this.modelDate || [undefined, undefined]).map(v => !v ? undefined :
            new Date(transformModelDateToComponentDate(v, "YYYY-MM-DD", this.options).date)
        );

        render(
            <div className="form-control">
                <DateRangePicker
                    block
                    isoWeek={true}
                    // disabledDate={DateRangePicker.afterToday()}
                    locale={locale}
                    ranges={this.options?.ranges}
                    value={value}
                    renderValue={this.renderValue}
                    showOneCalendar
                    placeholder="&nbsp;" // need this to prevent showing ugly "YYYY-MM-DD ~ YYYY-MM-DD" placeholder
                    onChange={this.onChange}
                />
            </div>
            ,
            this.element
        )
    }
}
