import * as _ from "lodash";

export class ObjectSumValueConverter {
    //noinspection JSUnusedGlobalSymbols
    toView(value) {
        let sum = 0;

        _.forEach(value, item => { sum += item });

        return sum;
    }
}
