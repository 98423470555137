import {bindable, LogManager, bindingMode} from 'aurelia-framework';
import moment from "moment-timezone";
import * as _ from "lodash";

import "./date-input-native-implementation.less";

const logger = LogManager.getLogger('DateInputNativeImplementation');
//logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

/**
 * @fixme integrate into date-input
 */
export class DateInputNativeImplementation
{
    @bindable({defaultBindingMode: bindingMode.oneWay}) date;
    @bindable({defaultBindingMode: bindingMode.oneWay}) time;

    @bindable options;
    @bindable withTime;

    @bindable valueChanged;

    // We have to keep copy of passed date/time values in inner component state
    // because we want to handle invalid component state properly (when date is null
    // or invalid, but time is present).
    _date;
    _time;
    min = '1900-01-01';
    max = '2199-12-31';

    // Component should not update _time when date-input binds { date: null, time: "00:00" }
    // because otherwise we will lose user made changes in time input
    _shouldReceiveTimeFromParentComponent = true;

    constructor() {
        const input = document.createElement('input');
        input.setAttribute('type', 'date');

        this.native = 'text' !== input.type;
    }

    dateChanged(newDate)
    {
        this._date = newDate;

        if (this._date === null) {
            this._shouldReceiveTimeFromParentComponent = false;
        } else {
            this._shouldReceiveTimeFromParentComponent = true;
            this._time = this.time; // If timeChanged occurred before dateChanged, we need to update time here as well
        }
    }

    timeChanged(newTime)
    {
        if (this._shouldReceiveTimeFromParentComponent) {
            this._time = newTime;
        }
    }

    setNow()
    {
        const date = moment();

        this._date = date.format('YYYY-MM-DD');
        this._time = this.withTime ? date.format('HH:mm') : '00:00';

        this.onValueChanged();
    }

    onValueChanged()
    {
        logger.debug('new value', _.cloneDeep(this.dateInput.value));
        logger.debug('validityMessage', _.cloneDeep(this.dateInput.validationMessage));
        logger.debug('validityValue', _.cloneDeep(this.dateInput.validity.valid));
        logger.debug('time changed', _.cloneDeep(this._time));

        if (this._time === '') {
            this._time = "00:00"; // Make null value impossible to enter
        }

        this.valueChanged({ date: this._date, time: this._time });
    }

    optionsChanged() {
        const dateFormat = /^\d\d\d\d-\d\d-\d\d/;
        this.min = dateFormat.test(this.options.min) ? this.options.min : '1900-01-01';
        this.max = dateFormat.test(this.options.max) ? this.options.max : '2199-01-01';
    }
}
