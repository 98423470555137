import {createUseStyles} from "react-jss"
import {accountingStyles} from "../../accounting/styles"

export const labelCol = {xs: 10, md: 14, lg: 17}
export const priceCol = {xs: 8, md: 4, lg: 4, className: "text-right"}
export const percentageCol = {xs: 3, md: 2, lg: 1, className: "text-right"}
export const actionCol = {xs: 3, md: 2, lg: 1, className: "text-right"}

export const useStyles = createUseStyles({
    ...accountingStyles,
    panel: {
        padding: "1rem",
        "& .rs-form-horizontal .rs-form-group label.rs-control-label": {
            width: "inherit"
        }
    },
    assignment: {
        marginBottom: ".5rem",
        paddingBottom: ".5rem",
        borderBottom: "1px solid #eee"
    },
    check: {
        color: "green",
        fontWeight: 900
    }
})
