import {bindable, customElement, inject, LogManager} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {transformComponentDateToModelDate, transformModelDateToComponentDate} from "./transformer";

const logger = LogManager.getLogger('DateInput');

//logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@customElement('sio-date-input')
@inject(BindingSignaler, Element)
export class DateInput {
    @bindable({defaultBindingMode: 2}) modelDate;

    @bindable format = 'YYYY-MM-DDTHH:mm:ssZ';
    @bindable options = {};
    @bindable withTime = true;
    @bindable readonly = false;
    // `date` and `time` are two internal component values,
    // both of these properties have string type and converted to modelDate on change.
    date = null;
    time = null;

    viewChange = false;

    constructor(signaler, element) {
        this.signaler = signaler;
        this.element = element;
    }

    get pickerType() {
        return 'native';
    }

    bind() {
        // We need to wait till options are bound. This is why bind() here is mandatory.
        this._modelDateChanged();
    }

    modelDateChanged(newValue, oldValue) {
        //If viewChange the change was initiated by a view change, so we do NOT need to update
        //See https://stackoverflow.com/a/45155901
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        this._modelDateChanged();
    }

    _modelDateChanged() {
        logger.debug('Value changed - To', this.modelDate);

        const {date, time} = transformModelDateToComponentDate(this.modelDate, this.format, this.options);

        this.date = date;
        this.time = time;

        this.signaler.signal('sio-date-input-changed');
    }

    updateModelDate(newValue) {
        const {date, time} = newValue;

        this.date = date;
        this.time = time;

        this.viewChange = true;
        this.modelDate = transformComponentDateToModelDate(date, time, this.format, this.options);

        //Needed otherwise binding might not be up-to-date
        setTimeout(() => {
            this.element.dispatchEvent(new CustomEvent('sio-change', {detail: {value: this.modelDate}}));
        }, 0);
    }
}
